<template>
  <div class="header-recommendation">
    <div class="navbar-section">
      <div class="container-fluid">
        <div class="row">
          <div class="navbar-header">
            <div class="promo-logo">
              <img src="/static/img/icons/soco-logo.svg" height="40" alt="SOCO" />
            </div>

            <div v-if="auth.userProfile" class="user-menu">
              <div class="user-img">
                <img v-if="auth.userProfile" :src="auth.userProfile.image" @error="imageError($event)" />
                <img v-else src="/static/img/avatar-soco.png" alt="SOCO" />
                <span>Hi, {{ auth.userProfile.name }}</span>
              </div>
              <div class="userlog">
                <p>
                  <a id="show-modal" @click.prevent="logoutQRSession()">LOG OUT</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="top-section">
      <div class="container-fluid">
        <div class="row">
          <div class="top-main">
            <h5 class="beauty-title">Beauty Vending Machine</h5>
            <!-- <p class="vending-point"><img src="/static/img/vending-machine/icon-SP.svg" alt="SOCO" width="70" height="70" /> <span>1.800</span></p> -->
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <div class="loader">
              <!-- <img src="/static/img/icons/loading.svg" alt="SOCO" /> -->
              <transition name="loader">
                <div class="loaderspinwrap">
                  <div class="loaderspin"></div>
                </div>
              </transition>
            </div>
            <h4>Logging Out</h4>
            <p>Terima kasih sudah menggunakan</p>
            <p>SOCO Interactive Recommendation Machine</p>
          </div>
        </div>
      </div>
      <div class="overlay"></div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

let CancelToken;
let source;

export default {
  name: 'headerRecommendation',
  directives: {
    ClickOutside,
  },
  data() {
    return {
      showSearchPopup: false,
      globalSearch: [],
      keyword: '',
      search: false,
    };
  },
  computed: {
    userSkintypes() {
      return this.$store.state.auth.userSkintypes;
    },
    showModal() {
      return this.$store.state.QRcode.isLoggingOut;
    },
    auth() {
      return this.$store.state.auth;
    },
  },
  created() {
    // this.getGlobalSearch()
    this.$store.dispatch('me');
  },
  methods: {
    triggerHideSearchPopup() {
      const thisel = this;
      setTimeout(function () {
        thisel.showSearchPopup = false;
      }, 500);
    },
    triggerShowSearchPopup() {
      this.showSearchPopup = this.keyword.length > 3 ? true : false;
    },
    getGlobalSearch(e) {
      if (e.code !== 'Enter') {
        if (this.keyword.length > 3) {
          this.showSearchPopup = true;
          if (typeof source !== 'undefined') {
            source.cancel(false);
          }
          CancelToken = this.$MS_SOCO_PUBLIC_API_URL.CancelToken;
          source = CancelToken.source();

          this.$MS_SOCO_PUBLIC_API_URL
            .get(`/global-search`, {
              params: {
                filter: {
                  keyword: this.keyword,
                  types: ['add-review'],
                  limit: 3,
                },
              },
              cancelToken: source.token,
            })
            .then((res) => {
              this.globalSearch = res.data.data['add-review'];
            });
        } else {
          this.showSearchPopup = false;
          if (typeof source !== 'undefined') {
            source.cancel(false);
          }
        }
      } else {
        if (typeof source !== 'undefined') {
          source.cancel(false);
        }
        this.showSearchPopup = false;
        this.$router.push({ path: '/recommendation/search-product', query: { q: this.keyword } });
      }
    },
    searching() {
      this.showSearchPopup = false;
    },
    logoutQRSession() {
      this.$store.dispatch('destroyQrSession', {
        router: this.$router,
        redirectUrl: '/vending-machine/scan-qr',
      });
    },
    imageError(e) {
      e.target.src = '~static/img/avatar-soco.png';
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'header';

body {
  background: #f8f8f8;
}

.loaderspin {
  border: 7px solid $pinkalt2;
  border-top: 7px solid $pinkdarkers;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0px auto 0;
  &.loader-leave-to {
    display: none;
  }
  &.withtext {
    margin-top: 48px;
    margin-bottom: 24px;
  }
}
.loaderspinwrap {
  text-align: center;
  width: 100%;
  p {
    color: $grey;
  }
}
</style>
